import React from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from '@contexts/user-context';
import { useInView } from 'react-intersection-observer';
import s from './UserIdentityChecklist.module.scss';
import LoadingProfile from './LoadingProfile';
import LoggedOut from './LoggedOut';
import LoggedIn from './LoggedIn';

const propTypes = {
  patronusData: PropTypes.array.isRequired,
  header: PropTypes.string,
  analyticsParams: PropTypes.shape({
    verticalIndex: PropTypes.number.isRequired,
  }),
  housePercentages: PropTypes.shape({
    gryffindor: PropTypes.number.isRequired,
    ravenclaw: PropTypes.number.isRequired,
    hufflepuff: PropTypes.number.isRequired,
    slytherin: PropTypes.number.isRequired,
  }).isRequired,
};

const UserIdentityChecklist = ({
  patronusData,
  header = 'Your Wizarding Checklist',
  analyticsParams = {},
  housePercentages,
}) => {
  const { profile, loadingProfile, isLoggedIn } = useUserContext();

  const [sectionRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <div className={s.root} ref={sectionRef}>
      {loadingProfile && <LoadingProfile />}
      {!loadingProfile && !isLoggedIn && (
        <LoggedOut
          isInView={inView}
          analyticsParams={analyticsParams}
          housePercentages={housePercentages}
        />
      )}
      {!loadingProfile && isLoggedIn && (
        <LoggedIn
          profile={profile}
          patronusData={patronusData}
          header={header}
          analyticsParams={analyticsParams}
          isInView={inView}
        />
      )}
    </div>
  );
};

UserIdentityChecklist.propTypes = propTypes;
export default UserIdentityChecklist;
