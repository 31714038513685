import React from 'react';
import { ReactComponent as GryffindorCrest } from '../img/gryffindor-crest.svg';
import { ReactComponent as RavenclawCrest } from '../img/ravenclaw-crest.svg';
import { ReactComponent as HufflepuffCrest } from '../img/hufflepuff-crest.svg';
import { ReactComponent as SlytherinCrest } from '../img/slytherin-crest.svg';
import s from './LoadingProfile.module.scss';

const LoadingProfile = () => {
  return (
    <div className={s.container}>
      <GryffindorCrest className={s.houseCrest} />
      <RavenclawCrest className={s.houseCrest} />
      <HufflepuffCrest className={s.houseCrest} />
      <SlytherinCrest className={s.houseCrest} />
    </div>
  );
};

export default LoadingProfile;
